iframe {
    width: 95%;
    height: 30vh;
}

.parentdiv {
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
}

.margin {
    padding-left: 5%;
}