.createPostPage {
    width: 100%;
    height: calc(100vh - 80px);
    display: grid;
    place-items: center;
    gap: 5%;
  }

  .cpContainer {
    width: 500px;
    height: auto;
    padding: 3%;
    background-color: black;
    border-radius: 12px;
    color: white;
    display: flex;
    flex-direction: column;
  }
  .cpContainer h1 {
    text-align: center;
  }
  .cpContainer label {
    font-size: 25px;
  }
  .cpContainer .inputGp {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  
  .inputGp input,
  .inputGp textarea {
    font-size: 18px;
    border: none;
    border-radius: 2px;
    padding: 5%;
  }
  
  .inputGp input {
    height: 3vw;
  }
  .inputGp textarea {
    height: 70%;
  }
  
  .cpContainer button {
    margin-top: 20px;
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
  }

  .hvr-fade {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
  }
  .hvr-fade:hover, .hvr-fade:focus, .hvr-fade:active {
    background-color: rgb(240, 229, 167);
    color: black;
  }

  .switch_btn{
      border:solid;
      border-radius: 1vw;
      cursor: pointer;
      width: 20%;
      height: 4vw;
      font-size: 2vw;
      color: white;
      box-shadow: rgba(0, 0, 0, 0.664) 0px 1vw 2.3vw;
      justify-content: center;
      border-color: black;

    }
  .switch_text{
    color: black;
    line-height: 4vw;
}

.back_btn{
  border: solid;
  border-radius: 1vw;
  cursor: pointer;
  width: 20%;
  height: 4vw;
  font-size: 2vw;
  box-shadow: rgba(0, 0, 0, 0.664) 0px 1vw 2.3vw;
  justify-content: center;
  border-color: black;
  color: black;
  line-height: 4vw;
  text-decoration: none;
  text-align: center;
}

.back_btn:hover{
  color: black;
}

@media screen and (max-width: 1200px){
  .switch_text{
    color: black;
    line-height: 5vw;
  }
  .switch_btn{
    width: 30%;
    height: 5vw;
    font-size: 3vw;
  }
  .back_btn{
    width: 30%;
    height: 5vw;
    font-size: 3vw;
    line-height: 5vw;
  }
 }

 @media screen and (max-width: 550px){
  .switch_btn {
    font-size: 6vw;
    width: 50%;
    height: 10vw;
    border-radius: 2vw;
  }
  .switch_text{
    color: black;
    line-height: 10vw;
  }
  .back_btn {
    font-size: 6vw;
    width: 50%;
    height: 10vw;
    border-radius: 2vw;
    line-height: 10vw;
  }
 }


  