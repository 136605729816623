.contact__form .form-control {
    padding: 1.75rem .75rem;
    line-height: 1.5;
    color: black;
    background-color: rgb(240, 229, 167);
    border-radius: 0 !important;
    border: 1px solid white; 
 }
 
 .contact__form input.form-control {
 
   margin-bottom: 2em;
   height: calc(2.5em + .75rem + 2px);
 
 }
 
.border_contact {

    border:2px solid black;
    padding: 5%;
    border-radius: 15px;
}
 
.btn {
   display:inline-block;
   padding:0.3em 1.2em;
   margin:0 0.1em 0.1em 0;
   border:0.16em solid black;
   border-radius:2em;
   box-sizing: border-box;
   text-decoration:none;
   font-family:'Roboto',sans-serif;
   font-weight:300;
   color:black;
   text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
   text-align:center;
   transition: all 0.2s;

}

.btn:active, .btn:focus {
   color:rgb(240, 229, 167);
   box-shadow: 0 8px 16px 0 rgba(182, 164, 0, 0.527);
   outline: none;
   -webkit-appearance: none;
}

.btn:hover {
   color: white;
   background-color: black;
}
@media all and (max-width:30em) {
   .btn {
      display:block;
      margin:0.2em auto;
   }
}

form-control::-moz-placeholder {
   color: black;
   opacity: 1;
 }
 
 .form-control::-ms-input-placeholder {
   color: black;
 }
 
 .form-control::-webkit-input-placeholder {
   color: black;
 }
 
 .form-control::-ms-expand {
   border: 0;
   background-color: transparent;
 }

 .contact-div {
   margin-bottom: 5%;
}

@media screen and (max-width: 1200px){
   .contact-div {
       margin-bottom: 10%;
   }
}
