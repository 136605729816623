.homePage {
    padding-top: 5vw !important;
    width: 100%;
    min-height: calc(100vh - 13vw);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .homePage .post {
    width: 60vw;
    height: auto;
    /* max-height: 60vw; */
    background-color: rgb(250, 250, 250);
    box-shadow: rgba(0, 0, 0, 0.568) 0px 1vw 2.3vw;
    margin: 3vw;
    padding: 3vw;
    border-radius: 4vw;
  }
  
  .button_redirect {

    border-radius: 1.5vw;
    padding: 1vw;
    border-color: black;
    background-color: rgb(250, 250, 250);
    color: black;
    font-size: 1.5vw;
  }

  .button_redirect:hover {

    border-radius: 1.5vw;
    padding: 1vw;
    border-color: black;
    background-color: black;
    color: white;
    font-size: 1.5vw;
  }

  .timestemp_css {
    font-size: 1.2vw;
  }

  .bin_button {

    top: 1vw;
    right: 1vw;
  }

  .title_mine {
    font-size: 3vw !important;
  }

  @media screen and (max-width: 1200px){
   .homePage {
      padding-top: 6vw !important;
   }
   .homePage .post {
      width: 80vw;
      height: auto;
      max-height: 60vw;
      background-color: rgb(250, 250, 250);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 1vw 2.3vw;
      margin: 3vw;
      padding: 3vw;
      border-radius: 4vw;
    }
    
    .button_redirect {
  
      border-radius: 2.7vw;
      padding: 1vw;
      border-color: black;
      background-color: rgb(250, 250, 250);
      color: black;
      font-size: 3vw;
    }
  
    .button_redirect:hover {
  
      border-radius: 2.7vw;
      padding: 1vw;
      border-color: black;
      background-color: black;
      color: white;
      font-size: 3vw;
    }
  
    .timestemp_css {
      font-size: 2.5vw;
    }
    .title_mine {
      font-size: 6vw !important;
    }
  }

  @media screen and (max-width: 550px){
    .homePage{
      padding-top: 16vw !important;
    }
    .homePage .post {
      width: 80vw;
      height: auto;
      max-height: 60vw;
      background-color: rgb(250, 250, 250);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 1vw 2.3vw;
      margin: 3vw;
      padding: 3vw;
      border-radius: 4vw;
    }
    
    .button_redirect {
  
      border-radius: 2.7vw;
      padding: 1vw;
      border-color: black;
      background-color: rgb(250, 250, 250);
      color: black;
      font-size: 3vw;
    }
  
    .button_redirect:hover {
  
      border-radius: 2.7vw;
      padding: 1vw;
      border-color: black;
      background-color: black;
      color: white;
      font-size: 3vw;
    }
  
    .timestemp_css {
      font-size: 2.5vw;
    }
    .title_mine {
      font-size: 6vw !important;
    }
  }