  .titlugoogle{
    text-align: center;
    font-size: 2.5vw;
  }

  @media screen and (max-width: 1200px){
    .titlugoogle{
      text-align: center;
      font-size: 5vw;
    }
  }

  @media screen and (max-width: 550px){
    .titlugoogle{
      text-align: center;
      font-size: 5vw;
    }
  }