*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
   
.logo {
  height: 3rem;
}

.toggle {
  top: 10%;
}

@media screen and (min-width: 1650px) {
  .text {
    text-align: center;
    font-family: 'League Spartan', sans-serif;
    font-weight:bold;
    font-size: 2vh;
    letter-spacing: 0px;
    padding-left: 3vh;
    padding-right: 3vh;
    color: lightgray!important;
    opacity: 1;
    text-decoration: none;
  }
}
@media screen and (max-width: 1650px){
  .text {
    text-align: center;
    font-family: 'League Spartan', sans-serif;
    font-weight:bold;
    font-size: 2vh;
    letter-spacing: 0px;
    padding-left: 3vh;
    padding-right: 3vh;
    color: lightgray!important;
    opacity: 1;
    text-decoration: none;
  }
}
.navbar-dark{
  background: rgba( 0, 0, 0, 0.8 );
  box-shadow: 0 8px 32px 0 rgba(255, 230, 0, 0.062);
  backdrop-filter: blur( 10.5px );
  -webkit-backdrop-filter: blur( 10.5px );
}
.navbar-dark .navbar-toggler {
  color: transparent !important;
  border-color: transparent !important;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

.header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
  url("../../assets/scale.gif");
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
}

.middle {
  height: 80vh;
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.middle h1 {

  font-size: 6vh;
  text-align: center;
  color: white;
}
.nav-links:hover{
  color: goldenrod!important;
  /* border-bottom: 2px solid goldenrod; */
}
.Title_Colour {
  color: goldenrod!important;
}
.Title_Hover:hover{
  color: lightgray!important;
}
.wave{
  position: absolute;
  bottom: -0.5%;
}
.navbar-nav{
  text-align: right;
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: goldenrod;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

div.dropdown-menu.show {
  background: rgba( 0, 0, 0, 0.8 );
  box-shadow: 0 8px 32px 0 rgba(255, 230, 0, 0.062);
  backdrop-filter: blur( 10.5px );
  -webkit-backdrop-filter: blur( 10.5px );
}

a.dropdown-item {
  color: #ffffff; 
}

.LOGO_ul {

    width: auto;
    height: 3vw;
}

.hvr-bounce-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover, .hvr-bounce-in:focus, .hvr-bounce-in:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

@media screen and (max-width: 1200px){
  .LOGO_ul {
      width: auto;
      height: 6vw;
  }
}

@media screen and (max-width: 550px){
  .LOGO_ul {
    width: auto;
    height: 10vw;
}
}
