.about_me {
    width: 13vw;
    height: auto;
    border-radius: 1vw;
    display: flex;
    text-align: center;
    place-items: center;
}

.about_me-image {
    border-radius: 2rem;
    overflow:  hidden;
}

.about_cards {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-bottom: 3rem;
    margin-top: 5rem;
}

.about_card {
    background: rgb(240, 229, 167);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
}

.about_card:hover {
    background: transparent;
    border-color: rgb(240, 229, 167);
    cursor: default;
}

.about_icon {

    color: black;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
}

.about_card h5 {
    font-size:  0.95rem;
}

.about_card small {
    font-size: 0.7rem;
    color: black;
}

.about_content p {
    margin: 2rem 0 2.6rem;
    color: black;
}
.btn {
    background-color: rgb(240, 229, 167);
}

.btn:hover {

    border: black;
}

@media screen and (max-width: 1024px) {
    .about_me {
        margin-top: 8vw !important;
        width: 30vw;
        margin: 2rem auto 4rem;
    }

    .about_content p {

        margin: 1rem 0 1.5rem;
    }
}

@media screen and (max-width: 600px) {
   .about_me {
    margin-top: 15vw !important;
    width: 35vw!important;
    margin: 0 auto 3rem;
   }
   .about_cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
   }

   .about_content {
    text-align: center;
   }

   .about_content p{
    margin: 1.5rem 0;
   }
}

@media screen and (max-width: 1402px) {
    .about_me {
     width: 23vw;
     margin: 0 auto 3rem;
    }
}

@media screen and (max-width: 450px) {
    .about_me {
     width: 35vw;
     margin: 0 auto 3rem;
    }
}
@media screen and (max-width: 300px) {
    .about_me {
     width: 45vw;
     margin: 0 auto 3rem;
    }
}

.about-div {
    margin-bottom: 5%;
}

@media screen and (max-width: 1200px){
    .about-div {
        margin-bottom: 10%;
    }
}

@media screen and (max-width: 550px){
    .about-div {
        margin-bottom: 15%;
    }
}
.about_me_tot {
    
    display: flex;
    text-align: center;
    place-items: center;
}