.centru {
    justify-content: center;
}

.image-resize {
    height: 50%;
    width: 50%;
    margin:auto;
}

.card-center {
    display:flex;
}


.card-text{
  font-size: 1.6rem;
}

.header-text{
  font-size: 3vw;
}

.card-color {
    box-shadow: 0 8px 16px 0 rgba(182, 164, 0, 0.527) !important;
}
@media screen and (max-width: 992px){
    .card-color {
        box-shadow: 0 8px 16px 0 rgba(182, 164, 0, 0.527) !important;
        width: 70%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        
    }

    .card-text{
      font-size: 1.6rem !important;
    }

    .header-text{
      font-size: 8vw;
    }
    
  }

.grid-div {
    margin-bottom: 5%;
}

@media screen and (max-width: 1200px){
    .grid-div {
        margin-bottom: 10%;
    }

    .card-text{
      font-size: 1.2rem;
    }
}

.hvr-rectangle-out {
    display: flex;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    background: #e1e1e1;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-rectangle-out:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(240, 229, 167);
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-rectangle-out:hover, .hvr-rectangle-out:focus, .hvr-rectangle-out:active {
    color: white;
  }
  .hvr-rectangle-out:hover:before, .hvr-rectangle-out:focus:before, .hvr-rectangle-out:active:before {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .card-container{
    text-align: center;
  }

  .action:hover {
    color:black;
  }



  

  
