.page-start{
    margin-top: 5%;
}

.domain-div{
    margin-left: 5%;
    margin-right: 5%;
    background-color: rgb(236, 179, 33);
    border-radius: 15px;
}

.ultimul {
    margin-bottom: 5%;
}

.header-div {
    margin-bottom: 3%;
    visibility: hidden; 
    pointer-events: none;
}

.center{
    text-align: center;
}

.margin-paragraph {
    margin-left: 5%;
    margin-right: 5%;
}

@media screen and (max-width: 1200px){
    .header-div{
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .domain-div{
        margin-left: 5%;
        margin-right: 5%;
        background-color: rgb(236, 179, 33);
        border-radius: 15px;
    }
    .ultimul {
        margin-bottom: 10%;
    }
  }

  @media screen and (max-width: 550px){
    .header-div{
        margin-top: 5%;
        margin-bottom: 9%;
    }
    .domain-div{
        margin-left: 5%;
        margin-right: 5%;
        background-color: rgb(236, 179, 33);
        border-radius: 15px;
    }
  }


