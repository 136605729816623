.div-prop{
    background-color: black;
    justify-content: center;
    vertical-align: center;
}

.footer-text{
    color: white;
    text-align: center;
    vertical-align: center;
    font-size: 1.5vw;
}

@media screen and (max-width: 1200px){
    .footer-text{
        padding: 3%;
        font-size: 2vw
    }

    .hidden{
        display: none;
    }
   }
 
   @media screen and (max-width: 550px){
    .footer-text{
        padding: 5%;
        font-size: 3.5vw;
    }

    .hidden{
        display: none;
    }
   }
   
   .AnimatedGradientText{
    animation: gradient 5s ease-in-out infinite;
    background: linear-gradient(to right, #ee9ca7, #ffdde1, #2193b0, #6dd5ed);
    background-size: 300%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

   @keyframes gradient{
   
   0% {
     background-position: 0 50%;
   }
   50% {
     background-position: 100% 50%;
   }
   100% {
     background-position: 0 50%;
   }
}

.spantext {
    font-weight: bold;
}

.hidden {
    opacity: 0;
    cursor: default;
}
